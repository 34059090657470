/* Header */
header .logo img {
    max-height: 40px;
}
header .user-menu-wrapper {
    margin-top: 16px;
}
/* Colors */
.btn-link,
a {
    color: #288690;
}
.btn-link:hover,
a:hover {
    color: #73b0b7;
}
.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #73b0b7;
}
.dropdown-item.active,
.dropdown-item:active,
a.text-danger.dropdown-item.active:focus,
a.text-danger.dropdown-item:active:hover {
    background-color: #288690;
    color: #FFFFFF !important;
}
/* Select2 */
.field_select2 .select2 {
    width: 100% !important;
    max-width: 350px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #288690 !important;
}
/* FlatPickr */
.field_datepicker input.form-control[readonly],
.field_datetimepicker input.form-control[readonly],
.field_timepicker input.form-control[readonly] {
    background-color: #FFFFFF;
}
.nullable-control {
    display: none;
}
/* Vich File Input */
.input-file-container,
.input-file-container:active,
.input-file-container:active:focus,
.input-file-container:focus,
.input-file-container:hover {
    margin: 1px !important;
}
.input-file-container .btn-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 !important;
}
.input-file-container .btn-label .custom-file-label {
    margin: 0;
    background: none;
    border: 0;
}
.input-file-container .btn-label .custom-file-label::after {
    content: none;
    background: none;
}

@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap');

* {
    font-family: 'Sen', sans-serif !important;
}
body.easyadmin {
    background-color: #75acaf !important;
}
.navbar-custom-menu .user-avatar {
    color: #FFF;
    box-shadow: none;
}
.navbar-custom-menu .user-details {
    display: none;
}
.main-sidebar {
    background: transparent;
}
.sidebar-menu li:not(.treeview).active {
    background-color: #b1c6c9 !important;
}
.sidebar-menu li a {
    color: #FFF !important;
    padding: 5px;
}
.sidebar-menu li .fa {
    color: #cfe6ee !important;
    filter: none;
}
.sidebar-menu ul {
    padding: 5px;
    margin-top: 5px;
    margin-left: 25px;
    background-color: #7fbbc5;
    border-radius: 0 0 5px 5px;
}
.content-wrapper {
    background-color: #c9e1eb;
    padding: 25px;
    border-radius: 15px;
}
.content {
    box-shadow: none;
}
.content-header {
    background-color: #e8eae9;
}
.content-body {
    background-color: #f0f4f3;
}
.content-body table td.actions a i {
    color: #c2c2c2 !important;
}
#sidebar-resizer-handler,
#content-resizer-handler {
    display: none;
}
.pager .btn-label {
    display: none;
}
.pager .page-link {
    border: 0 !important;
    background-color: #e7ebea;
    border-radius: 50% !important;
    margin: 0 5px !important;
}
.pager .page-item i {
    vertical-align: unset;
}